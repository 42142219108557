<template>
  <BaseDialog v-model="openModal" size-class="!max-w-xl">
    <template #header> We'd love to hear your feedback </template>
    <template #default>
      <BaseTextarea v-model="feedbackBody" rows="5" placeholder="Ran into an issue or have product suggestions?" />
      <span class="mt-1 text-red-700" v-if="feedbackError"> Feedback can't be blank. </span>
    </template>
    <template #footer>
      <div class="mt-5 flex w-full items-end justify-end space-x-2">
        <BaseButton type="secondary" @press="openModal = false">Cancel</BaseButton>
        <BaseButton :disabled="buttonBusy" @press="sendFeedback"> Send Feedback </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import useHttp from '@/composeables/http';

const $http = useHttp();

const emits = defineEmits(['update:model-value']);

const props = defineProps({
  modelValue: Boolean,
  savvycalLink: String
});

//MODAL STATE
const openModal = computed({
  get: () => props.modelValue,
  set: value => emits('update:model-value', value)
});

//SENDING FEEDBACK
const feedbackBody = ref('');
const buttonBusy = ref(false);
const feedbackError = ref(false);

function sendFeedback() {
  if (feedbackBody.value.trim().length > 0) {
    buttonBusy.value = true;
    $http.post('/feedback', { feedback: feedbackBody.value });
    buttonBusy.value = false;
    feedbackBody.value = '';
    emits('update:model-value', false);
  } else {
    feedbackError.value = true;
  }
}
</script>
